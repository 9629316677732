// Vendor libs
@import 'vendor/magento-ui/lib'; // Magento UI

// Theme variables
@import 'variables';

// Magento/blank
.page-print {
    .logo {
        float: none;
        display: block;
        text-align: left;
    }
}

@media print {
    * {
        text-shadow: none !important;
        -webkit-filter: none !important; // Use in 41 Chrome
        filter: none !important;
    }

    // Black prints faster:h5bp.com/s
    a,
    a:visited {
        text-decoration: underline !important;
    }

    // Don't show links for images, or javascript/internal links
    pre,
    blockquote {
        border: 1px solid $color-gray60;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    .table-wrapper table {
        width: 100%;

        td {
            width: auto;
        }

        table-layout: fixed;

        & > tfoot > tr:first-child {
            // border-top: 1px solid $color-gray60;
        }
    }

    .box,
    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 1cm;
    }

    .block-content,
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    .block-content {
        page-break-before: avoid;
    }

    .block-title,
    h2,
    h3 {
        page-break-after: avoid;
    }

    .nav-toggle {
        display: none !important;
    }

    .sidebar,
    .nav-sections,
    .header.content > *[class],
    .panel.wrapper > *[class],
    .footer.content > *[class] {
        display: none;
    }

    .logo,
    .footer .copyright {
        display: block !important;
        margin: 10px 0;
    }

    .order-details-items {
        .order-items {
            .order-gift-message {
                &:not(.expanded-content) {
                    visibility: visible;
                    height: auto;
                }
            }
        }
    }

    .column.main {
        width: 100% !important;
        float: none !important;
    }

    .breadcrumbs {
        margin: 0 auto;
        text-align: right;
    }

    .footer.content {
        padding: 0;
    }
}

body.page-print {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    font-size: 11px;

    .logo {
        img {
            max-height: 70px;
            width: auto;
        }
    }

    main.page-main {
        box-sizing: border-box;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;

        div.page-title-wrapper {
            margin-top: 20px;

            h1.page-title {
                margin-right: 20px;
                display: inline-block;
                font-size: 18px;
                vertical-align: top;
                font-weight: bold;
                color: #653A8E !important;
            }

            span.order-status {
                padding: 4px 8px;
                border: 1px solid #919D9D !important;
                color: #5e6a6a !important;
                font-weight: bold;
                display: inline-block;
            }
        }

        div.order-details-items {
            margin-bottom: 10px;
        }

        div.block-order-details-view {
            div.box-order-billing-method {
                text-align: right;

                .box-content {
                    dl {
                        dt {
                            font-weight: normal;
                        }
                    }
                }
            }

            .box {
                div.box-content {
                    address {
                        a {
                            color: #000 !important;
                            text-decoration: none !important;
                        }
                    }
                }
            }
        }

        div.block-title, div.order-title {
            font-size: 22px;
            line-height: 49px;
            color: #653A8E !important;
            position: relative;

            strong {
                font-weight: normal;
                background: #fff !important;
                padding-right: 20px;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                height: 1px;
                width: 100%;
                z-index: -1;
                background: #653A8E !important;
                top: 50%;
            }
        }
    }
}

@import "print/table";
