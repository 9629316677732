table.table-order-items {
    .col {
        padding-left: 0;
    }

    thead {
        tr {
            th.col {
                font-weight: normal;
                border-bottom: 1px solid #919D9D !important;
                padding-bottom: 10px;
                padding-right: 10px;

                &:last-of-type {
                    padding-right: 0;
                }
            }

            th.subtotal {
                text-align: right;
            }
        }
    }

    tbody {
        &:before {
            content: '';
            display: block;
            height: 10px;
        }
        
        ul.items-qty {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        tr {
            border-bottom: 1px solid #E2E2E2 !important;

            td.subtotal, td.price {
                min-width: 90px;
            }

            td.subtotal {
                text-align: right;
            }

            td.qty {
                min-width: 100px;
            }

            td.sku {
                min-width: 150px;
            }

            td {
                padding-bottom: 10px;
                padding-right: 10px;

                &:last-of-type {
                    padding-right: 0;
                }

                dd {
                    margin-bottom: 0px;
                }
            }
        }
    }

    tfoot {
        &:before {
            content: '';
            display: block;
            height: 10px;
        }

        tr {
            th {
                text-align: right;
                padding: 5px 20px;
            }
            
            td {
                text-align: right;
            }
        }
    }
}
